import { Component, OnDestroy, OnInit } from '@angular/core';

import { faFileCsv as falFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { AbstractPage } from '../abstract-page';
import { forEachCombination } from '../../utils/objects';
import { Stringifiable } from '../../utils/types';
import { replaceVariablesInUrl } from '../../utils/url';
import { environment } from '../../../environments/environment';
import { enumToArray } from '../../utils/arrays';
import { BarometerPeriodEnum } from '../../models/domain/barometer-period-enum';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { RGBColor } from '../../utils/RGBColor';
import { ProjectService } from '../../services/project.service';
import { MetadataService } from '../../services/metadata.service';
import { OpenDataService } from 'src/app/services/opendate.service';
import { catchError, forkJoin, interval, map, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export type OpenDataProjects = Array<{
  title: string,
  badges: Array<Badge>,
  description?: string,
  license?: string,
  prefix?: string,
  variables?: { [name: string]: Array<Stringifiable> },
  urls?: Array<{ label: string, value: string }>,
}>;

export interface Badge {
  label: string;
  colors: {
    text: RGBColor;
    background: RGBColor;
  };
}

export const BADGE_COLORS_BY_TYPE = {
  filetype: {
    text: new RGBColor(255, 255, 255),
    background: new RGBColor(199, 52, 62),
  },
  service: {
    text: new RGBColor(255, 255, 255),
    background: new RGBColor(133, 77, 148),
  },
};

@Component({
  selector: 'app-opendata-page',
  templateUrl: './opendata-page.component.html',
  styleUrls: ['./opendata-page.component.scss']
})
export class OpendataPageComponent implements OnInit, OnDestroy {

  subscriptions: Array<Subscription> = [];
  readonly icons = {
    falFileCsv,
  };


  projects: OpenDataProjects = [
    // {
    //   title: 'opendata.atlasMobileGrid.title',
    //   description: 'opendata.atlasMobileGrid.description',
    //   baseUrl: environment.opendata.atlasMobileGrid.url,
    //   variables: {
    //     provider: DOMAIN.providers.map(provider => provider.value),
    //     technology: [TechnologyEnum._3G, TechnologyEnum._4G],
    //   },
    // },
    {
      title: 'opendata.atlasMobileArea.title',
      badges: [
        { label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype },
        { label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service },
        { label: 'Mobile', colors: BADGE_COLORS_BY_TYPE.service },
      ],
      description: 'opendata.atlasMobileArea.description',
      // license: '&copy; BIPT',
      prefix: 'AtlasMobile'
    },
    {
      title: 'opendata.atlasFix.title',
      badges: [
        { label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype },
        { label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service },
        { label: 'Landline', colors: BADGE_COLORS_BY_TYPE.service },
      ],
      description: 'opendata.atlasFix.description',
      prefix: 'AtlasFixed'
    },
    // {
    //   title: 'opendata.barometer.title',
    //   badges: [
    //     { label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype },
    //     { label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service },
    //     { label: 'TV', colors: BADGE_COLORS_BY_TYPE.service },
    //     { label: 'Telephone', colors: BADGE_COLORS_BY_TYPE.service },
    //   ],
    //   description: 'opendata.barometer.description',
    //   // license: '&copy; BIPT',
    //   baseUrl: environment.opendata.barometer.url,
    //   variables: {
    //     period: enumToArray(BarometerPeriodEnum),
    //   },
    // },
    // {
    //   title: 'opendata.driveTesting.title',
    //   description: 'opendata.driveTesting.description',
    //   // baseUrl: environment.opendata.atlasFix.url,
    //   // variables: {
    //   //   level: DOMAIN.areasLevels.map(level => level.value),
    //   // },
    // },
  ];

  constructor(
    protected translationHelper: TranslationHelperService,
    protected projectService: ProjectService,
    protected metadata: MetadataService,
    protected opendataService: OpenDataService
  ) {
    this.metadata.setImage(environment.baseHref + 'assets/images/og/data-portal.png');
    this.metadata.setSubtitle('opendata.title');
    this.metadata.setDescription('opendata.description');
    this.projectService.select(null);
    const group = projectService.groups.filter(c => c.group == "Data")[0]
    projectService.selectGroup(group)
  }

  ngOnInit() {
    this.fillProjectsUrls();
  }

  fillProjectsUrls() {
    const observables = this.projects.map((p) => {
      return this.opendataService.getSasUrl(p.prefix)
    });

    const subscription =
      interval(540000).pipe(
        startWith(0),switchMap(() =>
        forkJoin(observables).pipe(tap((res) => {
          res.forEach((r, i) => {
            this.projects[i].urls = [];
            for (let sas of r) {
              let label = sas?.split('/').pop().split('?')[0].split('.')[0];
              this.projects[i].urls.push({
                label: label,
                value: sas
              })
            }
          })
        }))

      ))
        .subscribe();


    this.subscriptions.push(subscription)
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
