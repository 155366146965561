import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { Observable, map, take, takeUntil } from "rxjs";
import { SaSUri } from "../models/sas-uri";

@Injectable()
export class OpenDataService {
  constructor(private http: HttpClient) {
  }

  getSasUrl(prefix: string) : Observable<string[]> {
    return this.http.post(environment.opendata.sasGenerator.url, { 'Container' : environment.opendata.sasGenerator.containerName , 'Prefix' : prefix }, {
        headers:  new HttpHeaders(
        {
        'x-functions-key': environment.opendata.sasGenerator.apiKey,
        }
    )}).pipe(
        take(1),
        map((sasuri: SaSUri) => sasuri.SasURI)
    );
  }
}
